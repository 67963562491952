import { Injectable, inject } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, map } from 'rxjs';

import { gql } from '../../__generated__/gql';
import { InferItemType } from '../utils/cast';

export type Auth = InferItemType<ReturnType<typeof AuthenticationService.prototype.getMe>>;

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  readonly autoRefresh$ = new BehaviorSubject<void>(undefined);
  private readonly apollo = inject(Apollo);

  private _user: Auth;

  getMe() {
    return this.apollo
      .query({
        query: gql(`
          query Me {
            me {
              avatar {
                uri
              }
              id
              roles
              firstName
              lastName
              email
            }
          }
        `),
        variables: {},
        fetchPolicy: 'no-cache',
      })
      .pipe(map(({ data }) => data.me));
  }

  get currentUser() {
    return this._user;
  }

  set currentUser(user: Auth) {
    this._user = user;
  }
}
